
body {
    font-family: 'Sarabun', sans-serif !important;
    background-color: red;
  }
.row-between { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.row-flexend {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 10px;

}
.td-overall {
  font-size: 0.8vw;
  cursor: pointer;
}
.td-content {
  font-size: 0.8vw;
  cursor: pointer;
}
.td-contentaccount {
  font-size: 1.2vw;
  cursor: pointer;
}
.font-tital{
  font-size: 0.4cm;
  color:#2898cb ;
}
.app.flex-row.align-items-center{

  background-image: linear-gradient(-90deg,#BED1DE,#F5F5F5 );
}
.font-a{
  font-size: 0.3cm;
  color:#2898cb;

}
.headform-create {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0px 10px;
}
.headform-create div{
 
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;


}

.head-doc-name{
  font-size: 0.4cm;
  margin-left: 5px;
  margin-bottom: 0px;
}
.head-doc{
  font-size: 0.5cm;
  color: #2898cb;
}



.card-container {
  margin-top: 10px ;
  margin :20px;
 
  border-radius: 20px !important;
  

}
.card-container-end{
  display: flex;
  justify-content: flex-end;
 
  margin-bottom: 5px;
}
.card-form-start{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.card-form-center{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.ant-select-selector{
  border-radius: 5px !important;
}
.label-inspace{
 white-space: nowrap !important;
}
.label-normal{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.border-line{
  margin-top: 20px;
  border-bottom:#e6e9ee solid 2px;
  margin-bottom: 20px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
  /* margin-top: 10px; */
  color: #666;
}

.column-center {
  display: table-cell;
  text-align: start;
  vertical-align: middle;
  padding-top: 4px;
}
.contain-margincusPO {
  margin-top: 3px;
}
.modal-scroll {
  height: 250px;
  overflow: scroll;
}

.disabled-row {
  background-color: #FAFAFA;
  pointer-events: none;
}

.test-css {
  font-size: 20px;
  color: red;
}